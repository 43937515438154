<template>
  <el-dialog v-model="specializedShow" title="修改" width="30%">
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
      class="demo-ruleForm"
      status-icon
    >
      <el-form-item label="	姓名" prop="name">
        <el-input v-model="ruleForm.name" />
      </el-form-item>
      <el-form-item label="身份证号" prop="id_card">
        <el-input v-model="ruleForm.id_card" />
      </el-form-item>

      <el-form-item label="系部" prop="college_name">
        <el-input v-model="ruleForm.college_name" />
      </el-form-item>
      <el-form-item label="专业" prop="domain_name">
        <el-input v-model="ruleForm.domain_name" />
      </el-form-item>
      <el-form-item label="层次" prop="level">
        <el-input v-model="ruleForm.level" />
      </el-form-item>
      <el-form-item label="年级" prop="grade">
        <el-input v-model="ruleForm.grade" />
      </el-form-item>
      <el-form-item label="班级" prop="class_name">
        <el-input v-model="ruleForm.class_name" />
      </el-form-item>
      <el-form-item label="班主任" prop="teacher">
        <el-input v-model="ruleForm.teacher" />
      </el-form-item>
      <el-form-item label="入学年份" prop="year">
        <el-date-picker
          v-model="ruleForm.year"
          type="year"
          placeholder="请选择入学年份"
          format="YYYY"
          value-format="YYYY"
          :size="size"
          style="width: 500px"
        />
      </el-form-item>
      <el-form-item label="缴费金额" prop="amt">
        <el-input type="number" v-model="ruleForm.amt" />
      </el-form-item>
      <el-form-item label="缴费渠道" prop="pay_type">
        <el-select
          placeholder="请选择缴费渠道"
          v-model="ruleForm.pay_type"
          style="width: 500px"
        >
          <el-option label="光大" :value="0" />
          <el-option label="微信" :value="1" />
        </el-select>
      </el-form-item>
      <el-form-item label="缴费开始时间" prop="start_time">
        <el-date-picker
          v-model="ruleForm.start_time"
          type="datetime"
          placeholder="缴费开始时间"
          format="YYYY/MM/DD HH:mm:ss"
          value-format="YYYY-MM-DD HH:mm:ss"
          :size="size"
          style="width: 500px"
        />
      </el-form-item>
      <el-form-item label="缴费结束时间" prop="end_time">
        <el-date-picker
          v-model="ruleForm.end_time"
          type="datetime"
          placeholder="缴费结束时间"
          format="YYYY/MM/DD HH:mm:ss"
          value-format="YYYY-MM-DD HH:mm:ss"
          :size="size"
          style="width: 500px"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="onOkBtn">确认</el-button>
        <el-button @click="closeBtn">取消 </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'
import { useVModel } from '@vueuse/core'
import { saveGraduatepayAPI, graduatepayInfoAPI } from '@/api/finance'
import { ElMessage } from 'element-plus'
/**
 *  props S
 */
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },

  studentID: {
    type: Number
  },

  onPaymentList: {
    type: Function
  }
})

defineEmits(['update:modelValue']) // 注册 v-model 事件
const specializedShow = useVModel(props)

watch(
  () => props.studentID,
  (val) => {
    onStudentDetail(val)
  },
  {
    deep: true
  }
)

const onStudentDetail = async (id) => {
  const data = await graduatepayInfoAPI({ id })
  ruleForm.value = data
}

/** props E */

/** 表单 S */
// From 实例
const ruleFormRef = ref(null)
const ruleForm = ref({
  id: '',
  name: '',
  id_card: '',
  pay_type: '',
  college_name: '',
  domain_name: '',
  level: '',
  grade: '',
  class_name: '',
  teacher: '',
  year: '',
  start_time: '',
  end_time: ''
})

// 验证规则
const rules = ref({
  name: [
    {
      required: true,
      message: '请输入缴费名称',
      trigger: 'blur'
    }
  ],
  id_card: [
    {
      required: true,
      message: '请输入身份证号码',
      trigger: 'blur'
    }
  ],
  pay_type: [
    {
      required: true,
      message: '请选择缴费渠道',
      trigger: 'blur'
    }
  ],
  college_name: [
    {
      required: true,
      message: '请输入系部',
      trigger: 'blur'
    }
  ],
  domain_name: [
    {
      required: true,
      message: '请输入专业',
      trigger: 'blur'
    }
  ],
  level: [
    {
      required: true,
      message: '请输入层次',
      trigger: 'blur'
    }
  ],
  grade: [
    {
      required: true,
      message: '请输入年级',
      trigger: 'blur'
    }
  ],
  class_name: [
    {
      required: true,
      message: '请输入班级',
      trigger: 'blur'
    }
  ],
  teacher: [
    {
      required: true,
      message: '请输入班主任',
      trigger: 'blur'
    }
  ],
  amt: [
    {
      required: true,
      message: '请输入缴费金额',
      trigger: 'blur'
    }
  ],
  year: [
    {
      required: true,
      message: '请输入入学年份',
      trigger: 'blur'
    }
  ],
  start_time: [
    {
      required: true,
      message: '必须选择输入开始时间',
      trigger: 'blur'
    }
  ],
  end_time: [
    {
      required: true,
      message: '必须选择输入开始时间',
      trigger: 'blur'
    }
  ]
})

/** 表单 E */

const closeBtn = () => {
  specializedShow.value = false
}

const onOkBtn = async () => {
  ruleFormRef.value.validate(async (valid) => {
    if (!valid) return
    await saveGraduatepayAPI(ruleForm.value)
    ElMessage.success('修改成功')
    props.onPaymentList()
    specializedShow.value = false
  })
}
</script>

<style></style>
